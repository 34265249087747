import React from 'react';

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.967" height="20.714" viewBox="0 0 20.967 20.714">
            <g
                fill="none"
                stroke="#8e8e93"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                transform="translate(-319.789 -56.941)"
            >
                <g transform="translate(320.848 58)">
                    <path d="M12.041 4.618H0l5.1 4.618"></path>
                    <path d="M4.618 5.103L0 0" transform="rotate(90 2.551 2.552)"></path>
                </g>
                <g data-name="arrow_tall" transform="rotate(180 169.848 38.298)">
                    <path d="M12.041 4.618H0l5.1 4.618"></path>
                    <path d="M4.618 5.103L0 0" transform="rotate(90 2.551 2.552)"></path>
                </g>
            </g>
        </svg>
    );
};
