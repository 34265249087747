export const GET_DAY = 'GET_DAY',
    GET_DAY_SUCCESS = 'GET_DAY_SUCCESS',
    GET_DAY_ERROR = 'GET_DAY_ERROR',
    GET_READING = 'GET_READING',
    GET_READING_SUCCESS = 'GET_READING_SUCCESS',
    GET_READING_ERROR = 'GET_READING_ERROR',
    GET_EXTERNAL_DAY = 'GET_EXTERNAL_DAY',
    GET_EXTERNAL_DAY_SUCCESS = 'GET_EXTERNAL_DAY_SUCCESS',
    GET_EXTERNAL_DAY_ERROR = 'GET_EXTERNAL_DAY_ERROR',
    SET_ZOOM = 'SET_ZOOM',
    TOGGLE_ZOOM_CONTROL = 'TOGGLE_ZOOM_CONTROL',
    DISMISS_IOS_PROMPT = 'DISMISS_IOS_PROMPT';
