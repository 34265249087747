const vespersTOC = {
    nachalo: 'Начало',
    molitva11: 'Молитва 11',
    utrenniePsalmi: 'Утренние псалмы',
    slavoslovie: 'Славословие',
    reading: 'Чтение Евангелия',
    oglashaemie: 'Молитва об оглашаемых',
    sugubaja: 'Сугубая ектения',
    molitva12: 'Молитва 12',
    glavopreklonnaja: 'Главопреклонная',
    otpust: 'Отпуст',
};
export default vespersTOC;
